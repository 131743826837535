body {
    background: var(--bodyBackground);
    width: 100%;
    margin: 0;
    padding: 0;
    color: var(--bodyTextColor);
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    line-height: 1.5em;
}

.body-wrapper {
    width: 100%;
    margin: 0 auto;
}

a,
.a:hover {
    color: var(--linkHoverColor) !important;
}

.navbar {
    padding: 0;
}

.logo {
    /* width: 140px; */
    /* height: 62px; */
    text-decoration: none !important;
    background: var(--logoBackground);
    background-repeat: no-repeat;
    background-color: var(--logoBackgroundColor, initial);
}

    .logo:hover {
        text-decoration: none !important;
    }

h1 {
    font-family: var(--h1FontFamily);
    font-size: var(--h1FontSize);
    font-weight: var(--h1FontWeight);
}

.nav-item .active {
    color: var(--primaryColor);
}

#main-wrapper {
    margin-top: 50px !important;
    width: 90%;
    margin: auto;
}

.form-control,
.form-control:focus {
    border: 1px solid var(--formControlBorderColor);
    color: var(--formControlColor);
    box-shadow: none;
}


input[type='radio']:after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: -2px;
    visibility: visible;
}

input[type='radio']:checked:after {
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 15px;
    top: -5px;
    left: 0;
    position: relative;
    background-color: var(--primaryColor);
    display: inline-block;
    visibility: visible;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px var(--primaryColor);
}

.btn-primary {
    background: linear-gradient(to right, var(--btnPrimaryBackgroundGradientColor1), var(--btnPrimaryBackgroundGradientColor2));
    padding: 2px 20px;
}

.mar-top-50 {
    margin-top: 50px !important;
}

.mar-top-20 {
    margin-top: 20px;
}

.mar-left-10 {
    margin-left: 10px;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
}

.componentwise-loader,
.full-body-loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999;
    opacity: 1 !important;
}

.componentwise-loader {
    position: absolute;
}

.full-body-loader {
    position: fixed;
}

.cm-backdrop,
.cm-backdrop-full-body {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--cmBackdropBackground);
    opacity: 0.8;
    z-index: 9999999;
}

.cm-backdrop {
    position: absolute;
}

.cm-backdrop-full-body {
    position: fixed;
}

.loader-text {
    color: var(--loaderTextColor);
    text-align: center;
    font-size: var(--loaderTextFontSize);
    margin-left: 10px;
}

.overlay-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    pointer-events: none;
}

    .overlay-box .body-wrapper {
        opacity: 0.1;
    }

.file-uploder {
    background: var(--fileUploaderBackground);
    text-align: center;
    vertical-align: middle;
    border: 2px dashed var(--fileUploaderBorder);
    border-radius: var(--fileUploaderBorderRadius);
    font-family: var(--fontFamily);
}

    .file-uploder .margin-35 {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .file-uploder .btn-open {
        background: var(--fileUploaderBackgroundColor);
        color: var(--fileUploaderColor);
        height: 60px;
        text-transform: uppercase;
        text-align: center;
    }

    .file-uploder .btn-open,
    .btn-open:hover,
    .btn-open:active,
    .btn-open:visited,
    .btn-open:focus {
        background-color: var(--primaryButtonBackgroundColor) !important;
        border-color: var(--primaryButtonBackgroundColor) !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .file-uploder .header-txt {
        font-size: var(--fileUploaderFontSize);
        margin-bottom: 20px;
    }

.btn-remove-file {
    display: block;
    height: 35px;
    width: 35px;
    border-radius: var(--btnRemoveFileBorderRadius);
    border: 2px solid var(--btnRemoveFileBorder);
    color: var(--btnRemoveFileColor);
    background: var(--btnRemoveFileBackground);
}

.btn-remove-file,
.btn-open:hover,
.btn-open:active,
.btn-open:visited,
.btn-open:focus {
    border-color: var(--btnRemoveFileBorder) !important;
    outline: none !important;
    box-shadow: none !important;
}

.file-name-text {
    color: var(--fileNameTextColor) !important;
    font-size: var(--fileNameTextFontSize);
    font-family: var(--fontFamily);
}

    .file-name-text li {
        list-style: none;
    }

    .file-name-text ul {
        list-style: none;
        color: var(--fileNameSubTextColor);
    }

.error-txt {
    color: var(--errorTextColor);
    font-size: var(--errorTextFontSize);
}

.itar-disclaimer,
.itar-quote-info a {
    color: var(--itarDisclaimerColor) !important;
    font-size: var(--itarDisclaimerFontSize);
}

    .itar-disclaimer a {
        text-decoration: underline;
    }

h2 {
    font-size: var(--h2FontSize);
}

h3 {
    font-family: var(--h3FontFamily);
    font-size: var(--h3FontSize);
    font-weight: var(--h3FontWeight);
    line-height: 1.3em;
}

h5 {
    font-family: var(--h5FontFamily);
}

label {
    font-family: var(--labelFontFamily);
    font-size: var(--labelFontSize);
    line-height: 1.5em;
}

.form-control {
    font-family: var(--formControlFontFamily);
    font-size: var(--fontSize);
    line-height: 1.5em;
    border-radius: var(--formControlBorderRadius);
}

.cm-global-lead-paragraph {
    /* font-family: "OpenSans-Light"; */
    font-size: var(--cmGlobalLeadFontSize);
    line-height: 1.5em;
}

.cm-global-suggestion-text {
    /* font-family: "OpenSans-Regular"; */
    font-size: var(--cmGlobalSuggestionFontSize);
    line-height: 1.5em;
}

button {
    text-transform: uppercase;
    font-family: var(--cmButtonFontFamily);
    font-size: var(--fontSize);
    line-height: 1.5em;
    border-radius: var(--buttonBorderRadius);
}

.cm-main-nav {
    /* font-family: "OpenSans-Regular"; */
    font-size: var(--cmMainNav);
    line-height: 1.5em;
}

.cm-button {
    text-transform: uppercase;
    font-family: var(--cmButtonFontFamily);
    font-size: var(--fontSize);
    line-height: 1.5em;
    border-radius: var(--cmButtonBorderRadius) 0.15em;
}

.text-secondary-color {
    color: var(--textSecondaryColor);
}

.custom-control-input:checked ~ .custom-control-label::before {
    border: var(--primaryButtonBackgroundColor) !important;
    background: var(--primaryButtonBackgroundColor) !important;
}

.min-height-100 {
    min-height: 100px;
}

@media screen and (max-width: 575px) {
    .cm-ttt-inputs .col {
        flex-basis: auto;
    }
}

/* table column header search button */

.swal2-confirm {
    background: var(--primaryButtonBackgroundColor) !important;
    color: var(--primaryButtonColor) !important;
    border: 1px solid var(--primaryButtonBorderColor) !important;
}

    .swal2-confirm:hover, .swal2-confirm:focus {
        background-color: var(--primaryButtonBackgroundColorHover) !important;
        color: var(--primaryButtonColorHover) !important;
        border: 1px solid var(--primaryButtonBorderColorHover) !important;
    }

.swal2-cancel, .swal2-deny {
    background: var(--btnSecondaryBackground) !important;
    color: var(--btnSecondaryColor) !important;
    border: 1px solid var(--btnSecondaryBorderColor) !important;
}

    .swal2-cancel:hover, .swal2-cancel:focus, .swal2-deny:hover, .swal2-deny:focus {
        background-color: var(--btnSecondaryBackgroundHover) !important;
        color: var(--btnSecondaryColorHover) !important;
        border: 1px solid var(--btnSecondaryBorderColorHover) !important;
    }

.popover {
    font-family: var(--fontFamily);
}

.accordion-icon-color svg {
    color: var(--primaryColor) !important;
}

.form-label {
    margin: 1rem 0 0.5rem 0 !important;
}

.app-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flex-vertical-center {
    display: flex;
    align-items: center;
}

.flex-right-align {
    justify-content: right;
}
